<template>
	<div style="background:#f7f7f7;">
		<div style="font-size: 14px;padding-bottom: 50px;">
			<!-- <van-nav-bar title="个人中心"  style="background: orange"/> -->
			<div class="myInfoContainer">
				<span style="color:white;font-size:20px;position:relative;top:10px;padding-bottom:10px;">个人中心</span>
				<div style="position:absolute;top:15px;right:20px;">
					<van-icon name="chat-o" color="white" size="24" @click="$router.push('/message')"
						:badge="noReadMsgNum <= 0 ? '' : noReadMsgNum >= 99 ? '99+' : noReadMsgNum" />
				</div>
				<div class="myInfo">
					<van-uploader accept="file" :after-read="afterRead" v-model="fileList" :max-count="100"
						:deletable="false" :preview-image="false" style="margin-top:-35px;border-radius:50%;">
						<van-image :src="userInfo.profileImg" width="65px" height="65px"
							style="border-radius:50%;overflow:hidden;box-shadow:2px 2px 2px #ededed;" />
					</van-uploader>
					<div style="font-size:18px;font-weight: bold;margin-bottom:4px;margin-top:10px;">
						{{userInfo.userName}}
					</div>
					<div @click="$router.push('/balanceLog')">
						<span
							style="color: #bdbdbd;display:block;font-size:14px;margin-bottom:2px;margin-top:5px;">总资产(元)</span>
						<span style="color:orange;font-size:16px;padding-bottom:10px;">{{userInfo.myBalance}}</span>
					</div>
				</div>
				<div class="myInfoBottom"></div>
			</div>
			<div class="van-grid001">
				 
		
			</div>
			<van-cell-group>
				<van-cell icon="orders-o" title="我的订单" value="全部" is-link size="large" to="/orderList?status=-1">
				</van-cell>
				<van-grid gutter="10" :border="false" :column-num="4">
					<van-grid-item icon="bill-o" text="待付款" to="/orderList?status=0"
						:badge="orderNumObj.NoPayOrderNum <= 0 ? '' : orderNumObj.NoPayOrderNum >=99 ? '99+' : orderNumObj.NoPayOrderNum"
						style="font-size:14px;"></van-grid-item>
					<van-grid-item icon="free-postage" text="待发货" to="/orderList?status=1"
						:badge="orderNumObj.NoSendOrderNum <= 0 ? '' : orderNumObj.NoSendOrderNum >=99 ? '99+' : orderNumObj.NoSendOrderNum"
						style="font-size:14px;"></van-grid-item>
					<van-grid-item icon="logistics" text="待签收" to="/orderList?status=2"
						:badge="orderNumObj.NoSignOrderNum <= 0 ? '' : orderNumObj.NoSignOrderNum >=99 ? '99+' : orderNumObj.NoSignOrderNum"
						style="font-size:14px;"></van-grid-item>
					<van-grid-item icon="notes-o" text="已完成" to="/orderList?status=3"
						:badge="orderNumObj.OverOrderNum <= 0 ? '' : orderNumObj.OverOrderNum >=99 ? '99+' : orderNumObj.OverOrderNum"
						style="font-size:14px;"></van-grid-item>
					<!-- <van-grid-item icon="after-sale" text="已退货" to="/returnList"
						:badge="orderNumObj.returnOrderNum <= 0 ? '' : orderNumObj.returnOrderNum >=99 ? '99+' : orderNumObj.returnOrderNum"
						style="font-size:14px;"></van-grid-item> -->
				</van-grid>
			</van-cell-group>
			<div class="van-grid001 van-grid002" v-if="isProxy()">
				<div class="van-grid-item" @click="navigateTo('/customerList')">
					<van-image :src="imgs.user002" rel="external nofollow"/>
					<van-cell title="门店管理"  />
				</div>
				
				<div class="van-grid-item" @click="navigateTo('/customerChange')">
					<van-image :src="imgs.user004" rel="external nofollow"  />
					<van-cell title="代门店下单"  />
				</div>
				
				<div class="van-grid-item" @click="navigateTo('/proxyOrderList?')">
					<van-image :src="imgs.user004" rel="external nofollow"  />
					<van-cell title="订单列表"  />
				</div>
				<div class="van-grid-item" @click="navigateTo('/proxyOrderStatistics?')">
					<van-image :src="imgs.user004" rel="external nofollow"  />
					<van-cell title="订单统计"  />
				</div>
				<div v-if="isProxyManager()" class="van-grid-item" @click="navigateTo('/proxyEarning')">
					<van-image :src="imgs.user004" rel="external nofollow"  />
					<van-cell title="我的收益"  />
				</div>
				<!-- <div v-if="isProxyManager()" class="van-grid-item" @click="navigateTo('/proxyClerkList')">
					<van-image :src="imgs.user004" rel="external nofollow"  />
					<van-cell title="业务员"  />
				</div> -->
			</div>
			<div class="van-grid001 van-grid002" v-if="!isProxy()">
				<div class="van-grid-item"  @click="navigateTo('/favorite')" >
					<van-image :src="imgs.user002" rel="external nofollow"/>
					<van-cell title="我的关注" to="/favorite" />
				</div>
				<!-- <div class="van-grid-item">
			   			<van-image :src="imgs.user003" rel="external nofollow" to="/footmark" />
			   			<van-cell title="我的足迹" to="/footmark" />
			   </div> -->
				<div class="van-grid-item"  @click="navigateTo('/address')" >
					<van-image :src="imgs.user006" rel="external nofollow"  />
					<van-cell title="收货地址" />
				</div>
				<div class="van-grid-item" @click="navigateTo('/myAfterSale')" >
					<van-image :src="imgs.user004" rel="external nofollow" />
					<van-cell title="我的售后"  />
				</div>
				<div class="van-grid-item" @click="navigateTo('/myComment')" >
					<van-image :src="imgs.user005" rel="external nofollow"  />
					<van-cell title="我的评价"  />
				</div>
				<div class="van-grid-item"  v-if="!isProxy()">
					<van-image :src="imgs.user001" rel="external nofollow"@click="navigateTo('/proxyApply')"  />
					<van-cell title="成为代理商"  to="/proxyApply" />
				</div> 
				<div class="van-grid-item" @click="navigateTo('/password')" >
					<van-image :src="imgs.user007" rel="external nofollow" />
					<van-cell title="密码管理" />
				</div>
				<div class="van-grid-item" @click="navigateTo('/atpage?code=FEAYiSUhGMKbHeA')" >
					<van-image :src="imgs.user008" rel="external nofollow"  />
					<van-cell title="关于我们"  />
				</div>
			</div>
			<van-cell-group>
				<van-cell title="账号退出" @click="logout()" style="text-align:center;margin-top:10px;" />
			</van-cell-group>
			<bottomTabNav :index="4"></bottomTabNav>
		</div>
	</div>
</template>
<script type="text/javascript">
	import bottomTabNav from "@/components/bottomTabNav";
	import request from "@/utils/request";
	import {
		Toast
	} from 'vant';
	export default {
		// name: "MinePage",
		components: {
			bottomTabNav
		},
		data() {
			return {
				fileList: [],
				showOverTime: true,
				userInfo: {
					profileImg: require("./image/profile.jpg"),
					userName: "清风明月",
					myBalance: '0.00',
				},
				imgs: {
					"user001": require("./image/usericon001.png"),
					"user002": require("./image/usericon002.png"),
					"user003": require("./image/usericon003.png"),
					"user004": require("./image/usericon004.png"),
					"user005": require("./image/user001.png"),
					"user006": require("./image/user002.png"),
					"user007": require("./image/user003.png"),
					"user008": require("./image/user004.png")
				},
				orderNumObj: {}
			};
		},
		created() {
			let wxCode = this.getUrlParam("code");
			if (wxCode) {
				this.refreshToken(wxCode);
			}
			this.init();
			this.hideShare();
		},
		methods: {
			refreshToken(code) {
				request.post('/api/wx/refreshOpenId', {
					code: code
				}).then(res => {

				});
			},
			init() {
				let isLogin = this.checkLogin(true);
				if (!isLogin) {
					return;
				}
				request.post("/api/mine/getLoginUserInfo").then(response => {
					this.userInfo.userName = response.data.userName;
					this.userInfo.myBalance = response.data.myBalance.toFixed(2);
					let imgUrl = this.getImgUrl(response.data.avator);
					let userInfo=this.getUserInfo();
					userInfo.userType=response.data.userType;
					userInfo.userName=response.data.userName;
					this.setData("userInfo", userInfo);
					this.userInfo.profileImg = imgUrl || require("./image/profile.jpg");
				});
				request.post("/api/order/queryOrderNumByStatus").then(data => {
					this.orderNumObj = data;
				});
			},
			afterRead(file) {
				let url = "/api/common/upload";
				let fd = new FormData;
				fd.append("file", file.file);
				this.$axios.post(url, fd).then((rs) => {
					let res = rs.data;
					if (res.code == 0) {
						this.userInfo.profileImg = res.url;
						request.post("/api/mine/editLoginUserInfo", {
							avator: res.fileName
						}).then(response => {
							if (response.code != 0) {
								Toast.fail("上传失败");
							} else {
								//this.$router.go(0);
							}
						});
					} else {
						Toast.fail(res.msg || '上传失败');
					}
				}).catch(err => {
					alert(err)
				});
			},
			logout() {
				let that = this;
				this.confirm("确定要退出账号吗?",()=> {
					this.doPost("/api/mine/logout");
					this.onLogout(true);
				})
			}
		},
		computed: {
			noReadMsgNum() {

				return parseInt(this.storage.get("noReadMsgNum") || "0") || 0;
			}
		}
	}
</script>
<style type="text/css" scoped="">
	.myInfoContainer {
		background: -webkit-linear-gradient(left, #ff6034, #ee0a24) !important;
		background: linear-gradient(to right, #ff6034, #ee0a24) !important;
	}

	.myInfoContainer {
		text-align: center;
		background: #feb74c;
		height: 180px;
		position: relative;
		margin-bottom: 40px;
	}

	.myInfo {
		text-align: center;
		background: #fff;
		width: 80%;
		margin: 75px auto 0;
		position: absolute;
		left: 10%;
		top: 17px;
		border-radius: 10px;
		height: 120px;
		box-shadow: 1px 2px 2px 1px #ededed;
	}

	.van-grid .van-grid-item {
		font-size: 14px !important;
	}

	.van-grid001 {
		background: #f7f7f7;
		padding: 5px;
		overflow: hidden;
		text-align: center;
	}

	.van-grid001 .van-grid-item {
		width: 25%;
		float: left;
	}

	.van-grid001 .van-grid-item {
		background: #f7f7f7;
	}

	.van-grid001 .van-cell {
		background: #f7f7f7 !important;
		padding: 5px;
	}

	.van-grid001 .van-image {
		width: 36px;
		height: 36px;
		display: block;
		margin: 0px auto;
	}

	.myInfo img {
		display: block;
		height: 70px;
		width: 70px;
		border-radius: 30px;
		margin: 0 auto 8px;
	}

	.myInfoBottom {
		width: 70%;
		margin: 0 auto;
		height: 70px;
		background: #fff;
		display: none;
		border-radius: 10px 10px 5px 5px;
		margin-top: -40px;
	}

	.van-grid-item__text {
		width: 14px !important;
	}

	.van-grid002 {
		background: #fff;
		margin: 10px auto;
		padding: 10px 0px;
	}

	.van-grid002 .van-grid-item {
		background: none;
	}

	.van-grid002 .van-cell {
		background: none !important;
	}
</style>
<style>
	.van-uploader img {
		border-radius: 100px;
	}
</style>